import { Component, Input, OnInit } from '@angular/core';
import { CorporateActionsData } from '../../-models-/corporate-action-data.model';
import { CoopActionDetailsService } from '../../coop-action-details.service';
import { CorporateAction } from 'src/api-connector/api-collections/cgt-view/cgt-view.types';

@Component({
  selector: 'app-corporate-actions-tab',
  templateUrl: './corporate-actions-tab.component.html',
  styleUrls: ['./corporate-actions-tab.component.css']
})
export class CorporateActionsTabComponent implements OnInit {
  @Input() data!: CorporateActionsData;

  // internal state
  currentlySelectedActivityId: string | null = null;

  constructor(private detailsService: CoopActionDetailsService) {}

  ngOnInit(): void {}

  get isLoadingActivity() {
    return this.detailsService.isLoading;
  }

  get selectedActivityId() {
    return this.detailsService.selectedActivityId;
  }

  openCoopActionDetailsModal(item: CorporateAction) {
    this.currentlySelectedActivityId = item.activityId;
    this.detailsService.requestCoopActionDetailsModal(item);
  }
}
