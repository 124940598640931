import { Component, Input, OnInit } from '@angular/core';
import { addDollarSign } from '../../common-components/components-table/processors/add-dollar-sign';
import { DocumentLink } from '../../models/document-link.model';

@Component({
  selector: 'app-buyback-details-renderer',
  templateUrl: './buyback-details-renderer.component.html',
  styleUrls: ['./buyback-details-renderer.component.css']
})
export class BuybackDetailsRendererComponent implements OnInit {
  @Input() details: any;

  constructor() {}

  ngOnInit(): void {}

  /**
   * Get label-value pairs for the top infobox.
   */
  computeInfoboxEntries() {
    if (!this.details) {
      return;
    }

    const details = this.details;
    return toLabelValuePairArray({
      'Ex Date': details.exDate,
      Code: details.securityCode,
      Type: details.activityName,
      'Buyback Sale Date': details.buybackSaleDate,
      'Buyback Capital Component': addDollarSign(details.buybackCaptComp, 6),
      'Buyback Tax Excess': addDollarSign(details.buybackTaxExcess, 6),
      'Reinvestment Code': this.details.reinvestmentCode
    });
  }
}

export function toLabelValuePairArray(data: any) {
  const keys = Object.keys(data);
  const result = [];

  for (let key of keys) {
    result.push({
      label: key,
      value: data[key]
    });
  }

  return result;
}
