import { Component, Input, OnInit } from '@angular/core';
import { DocumentLink } from '../../models/document-link.model';
import { toLabelValuePairArray } from '../buyback-details-renderer/buyback-details-renderer.component';

@Component({
  selector: 'app-demerger-details-renderer',
  templateUrl: './demerger-details-renderer.component.html',
  styleUrls: ['./demerger-details-renderer.component.css']
})
export class DemergerDetailsRendererComponent implements OnInit {
  @Input() details: any;

  constructor() {}

  ngOnInit(): void {}

  computeInfoboxData() {
    return toLabelValuePairArray({
      'Ex Date': this.details.exDate,
      Code: this.details.securityCode,
      'Issue Code': this.details.issueCode,
      Ratio: this.getRatio(),
      'Original Cost Ratio': this.details.originalCostRatio,
      'New Cost Ratio': this.details.newCostRatio
    });
  }

  private getRatio() {
    return `${this.details.numerator} for ${this.details.denominator}`;
  }
}
