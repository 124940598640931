import { Component, Input, OnInit } from '@angular/core';
import { toLabelValuePairArray } from '../buyback-details-renderer/buyback-details-renderer.component';
import { addDollarSign, addPercentSign } from '../../common-components/components-table/processors/add-dollar-sign';
import { DocumentLink } from '../../models/document-link.model';

@Component({
  selector: 'app-schema-arrangement-renderer',
  templateUrl: './schema-arrangement-renderer.component.html'
})
export class SchemaArrangementRendererComponent implements OnInit {
  @Input() details: any;

  constructor() {}

  ngOnInit(): void {}

  computeInfoboxData(): any {
    const data = this.details;
    return toLabelValuePairArray({
      'Ex Date': data.exDate,
      Code: data.securityCode,
      'New Code': data.newCode,
      'Effective Date': data.effectiveDate,
      Ratio: `${data.numerator} for ${data.denominator}`,
      'Cash Proceeds': addDollarSign(data.cashProceeds, 6),
      'Market Value': addDollarSign(data.marketValue, 6),
      'Original Cost Ratio': addPercentSign(data.originalCostRatio, 4),
      'New Cost Ratio': addPercentSign(data.newCostRatio, 4),
      'Special Dividend Rate': data.specialDivRate
    });
  }
}
