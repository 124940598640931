<div class="container">
  <div class="filters">
    <div class="p-fluid row security-wrapper">
      <div class="lg:col-12 sm:col-12 md:col-12 p-0 full-width-security">
        <app-security-reference-search label="Security Code" [(ngModel)]="securityId" (ngModelChange)="onSearchFilter()"></app-security-reference-search>
      </div>
    </div>

    <!-- <span class="p-float-label p-input-icon-right">
      <i class="pi pi-times" *ngIf="(searchText || '').length > 0" (click)="onClearSearch()"></i>
      <input type="text" pInputText [(ngModel)]="searchText" (blur)="onSearchFilter()" (keyup.enter)="onSearchFilter()"
        placeholder="Enter to search" />
      <label>Search Security</label>
    </span> -->

    <span class="p-float-label">
      <p-dropdown [(ngModel)]="financialYear" [options]="taxYears" optionLabel="label"
        (onChange)="getTrustComponents()"></p-dropdown>
      <label for="financialYear">Financial Year</label>
    </span>

    <p-selectButton [options]="statusOptions" [(ngModel)]="isOutstanding" optionLabel="label" optionValue="value"
      (onChange)="getTrustComponents()" [allowEmpty]="false" />

    <p-button [disabled]="!canExport" class="export-btn" icon="pi pi-file-export" label="Export" (onClick)="dt.exportCSV()" />
  </div>

  <div>
    <p-table #dt [value]="filteredData" dataKey="securityId" styleClass="p-datatable-striped" [scrollable]="true"
      scrollHeight="500px" [resizableColumns]="true" [paginator]="true" [rows]="50" [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[25, 50, 100]" [loading]="isLoading" [exportFilename]="exportFilename" [columns]="columns">
      <ng-template pTemplate="header"  >
        <tr>
          <th pResizableColumn pSortableColumn="securityCode">Security Code <p-sortIcon
              field="securityCode"></p-sortIcon></th>
          <th pResizableColumn pSortableColumn="securityName">Security Name <p-sortIcon
              field="securityName"></p-sortIcon></th>
        </tr>
        <tr *ngIf="isLoading">
          <td colspan="8">
            <p-progressBar *ngIf="isLoading" mode="indeterminate"></p-progressBar>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-expanded="expanded">
        <tr>
          <td>{{ item.securityCode }}.{{ item.exchangeCode }}</td>
          <td>{{ item.securityName }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="2" style="text-align: center" class="text-secondary">No data found</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
