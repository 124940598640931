import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ReleaseNotesService {
  constructor(private http: HttpClient) {}

  generateNotes = (
    startDate: Date | string,
    endDate: Date | string,
    type: string
  ) => {
    const queryParams = new URLSearchParams(
      JSON.parse(JSON.stringify({ startDate, endDate }))
    );
    const reportType = type ? `/${type}` : '';
    return this.http.get(`api/release-notes${reportType}?${queryParams}`);
  };
}
