import { Component, OnInit } from '@angular/core';
import { DividendCalculatorService } from '../../../../-services-/dividend-calculator.service';

@Component({
  selector: 'app-dividend-calculator-table',
  templateUrl: './dividend-calculator-table.component.html',
  styleUrls: ['./dividend-calculator-table.component.css']
})
export class DividendCalculatorTableComponent implements OnInit {

  constructor(public dcService: DividendCalculatorService) { }

  ngOnInit(): void {

  }

}
