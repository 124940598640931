import { FinancialYear } from 'src/api-connector/utils/financial-year';

/**
 * Standard convertion of date to tax - year
 * It converts the date string to date and then returns the financial year based on AU standard
 * @param cellData
 */
export function convertToYear(dateString: string) {
  return new FinancialYear(dateString).getFinancialYear();
}
