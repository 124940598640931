import { round } from 'lodash-es';

/**
 * Standard rounding for use in most postprocessors
 * It rounds the number to 9 digits, then convert them to toPrecision strings
 * @param cellData
 */
export function roundAndNormalise(cellData: any, precision: number = 9) {
  return round(cellData, precision).toFixed(precision);
}
