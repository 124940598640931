import { Component, OnDestroy, OnInit } from '@angular/core';
import { CoopActionDetailsRequest, CoopActionDetailsService } from '../coop-action-details.service';
import { Subscription } from 'rxjs';
import { SwalError } from '../../../common/util/swal-mixins/swal-error';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApiHttpRequest } from '../../../../api-connector/api-http-request';
import { extractErrorText } from '../../../utilities/extract-error-text';
import { CorporateAction } from 'src/api-connector/api-collections/cgt-view/cgt-view.types';

@Component({
  selector: 'app-coop-action-popup',
  templateUrl: './coop-action-popup.component.html',
  styleUrls: ['./coop-action-popup.component.css']
})
export class CoopActionPopupComponent implements OnInit, OnDestroy {
  // ngModel
  open = false;

  activityType!: string;
  coopAction!: CorporateAction;

  details: any;
  private currentActivityId: string = '';

  constructor(private detailsService: CoopActionDetailsService, private router: Router) {}

  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    const detailsServiceRequestSubscription = this.detailsService.request.subscribe((req) => this.loadDetailsAndShow(req));
    const routeChangeSubscription = this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => this.onRouteChanged());
    this.subscriptions.push(detailsServiceRequestSubscription, routeChangeSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async loadDetailsAndShow(item: CoopActionDetailsRequest) {
    try {
      const { activityId, activity, description } = item;
      this.currentActivityId = activityId;
      this.activityType = activity;
      this.coopAction = item;
      this.details = await ApiHttpRequest.loadActivityDetailsData(activityId);
      this.details.description = description; // HACK: unknown type require this information, but the API ain't giving it. Hence...
      this.open = true;

      // debug
      // console.log(this.details);
    } catch (e: unknown) {
      await SwalError.fire(extractErrorText(e));
    } finally {
      this.detailsService.stopLoading();
    }
  }

  private onRouteChanged() {
    this.open = false;
  }

  sendEnquiry() {
    const url = `mailto:cgtview@estm.com.au?subject=Enquiry ${this.currentActivityId}`;
    window.open(url);
  }
}
