import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estimate-colour-box',
  templateUrl: './estimate-colour-box.component.html',
  styleUrls: ['./estimate-colour-box.component.css']
})
export class EstimateColourBoxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
