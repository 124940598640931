import { Component, Input, OnInit } from '@angular/core';
import { DividendEntryViewModel } from '../../../../-services-/models/dividend-entry-view-model.model';
import { DividendCalculatorService } from '../../../../-services-/dividend-calculator.service';

@Component({
  selector: 'app-dividend-calculator-individual-entry-units-input',
  templateUrl: './dividend-calculator-individual-entry-units-input.component.html',
  styleUrls: ['./dividend-calculator-individual-entry-units-input.component.css']
})
export class DividendCalculatorIndividualEntryUnitsInputComponent implements OnInit {

  @Input() item!: DividendEntryViewModel;

  /*ngModel*/ inputValue: number = 0;

  constructor(private dcService: DividendCalculatorService) { }

  ngOnInit(): void {
    this.inputValue = this.item.units;
  }

  postChange(): void {
    this.dcService.setUnitsForEntry(this.inputValue, this.item);
  }

}
