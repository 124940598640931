import { isNumber } from 'lodash-es';
import { roundAndNormalise } from './round-and-normalise';

/**
 * A processor to add a dollar sign in front
 * @param cellData
 * @param layer
 */
export function addDollarSign(cellData: any, precision = 9) {
  const valueToShow = isNumber(cellData) ? roundAndNormalise(cellData, precision) : cellData;
  return `$${valueToShow || 0}`;
}

export function addPercentSign(cellData: any, precision = 9) {
  const percNum = Number(cellData) * 100;
  const valueToShow = isNumber(percNum) ? roundAndNormalise(percNum, precision) : percNum;
  return `${valueToShow || 0}%`;
}
