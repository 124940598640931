<form class="p-fluid container row" [formGroup]="formGroup">
  <div class="lg:col-4 sm:col-6 md:col-4 full-width-security">
    <app-security-reference-search
      [isMandatory]="true"
      [includeAllSecurities]="true"
      label="Security Code"
      formControlName="securityId"
    ></app-security-reference-search>
  </div>

  <div class="lg:col-2 sm:col-6 md:col-3">
    <span class="p-float-label">
      <p-dropdown formControlName="financialYear" [options]="taxYears" optionValue="value" optionLabel="label"></p-dropdown>
      <label for="financialYear">Financial Year</label>
    </span>
  </div>

  <div class="lg:col-3 sm:col-6 md:col-1"></div>

  <div class="lg:col-3 sm:col-6 md:col-4">
    <span class="p-buttonset flex">
      <button
        pButton
        class="p-button-outlined p-button-primary p-fluid"
        label="Confirm"
        (click)="onConfirm()"
        icon="pi pi-check-circle"
        [disabled]="!formGroup.valid"
      ></button>
      <button
        pButton
        class="p-button-outlined p-button-danger"
        label="Reset"
        (click)="onReset()"
        icon="pi pi-refresh"
        [disabled]="isFormDirty"
      ></button>
    </span>
  </div>
</form>
