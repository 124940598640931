import { Component, OnInit } from '@angular/core';
import { DividendCalculatorService } from './-services-/dividend-calculator.service';

@Component({
  selector: 'app-dividend-calculator',
  templateUrl: './dividend-calculator.component.html',
  styleUrls: ['./dividend-calculator.component.css'],
  providers: [DividendCalculatorService]
})
export class DividendCalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
