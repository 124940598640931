import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PostProcessor } from './processors/processors';
import { nilProcessor } from './processors/nil-processor';
import { toPercentage } from './processors/to-percentage';
import { defaultCellProcessor } from './processors/default-processor';
import { AustralianFrankedProcessor } from './processors/australian-franked-processor';
import { convertToYear } from './processors/date-year-processor';
import { roundAndNormalise } from './processors/round-and-normalise';

@Component({
  selector: 'app-components-table',
  templateUrl: './components-table.component.html',
  styleUrls: ['./components-table.component.css']
})
export class ComponentsTableComponent implements OnInit, OnChanges {
  @Input() data: any;

  isOrDividend: boolean = false;
  isStapledDividend: boolean = false;
  isAndDividend: boolean = false;
  numberOfChoices!: number;

  //region Fields
  // Please see the Field interface for an explanation of what's going on.
  fields: Field[] = [
    { key: 'rate', displayName: 'Rate', postProcessor: nilProcessor },
    { key: 'payDate', displayName: 'Pay Date' },
    // { key: 'drpPrice', displayName: 'DRP Price', postProcessor: addDollarSign },
    // { key: 'bspPrice', displayName: 'BSP Price', postProcessor: addDollarSign },
    // { key: 'reinvestmentCode', displayName: 'Reinvestment Code' },
    { key: 'taxReportDate', displayName: 'Tax Year', postProcessor: convertToYear },
    { key: 'australianFranked', displayName: 'Australian Franked', postProcessor: AustralianFrankedProcessor },
    { key: 'australianUnfranked', displayName: 'Australian Unfranked' },
    { key: 'australianInterest', displayName: 'Australian Interest' },
    {
      key: 'australianOtherIncome',
      displayName: 'Australian Other Income'
    },
    { key: 'australianPreCGT', displayName: 'Australian Pre CGT' },
    { key: 'australianCGTFrozen', displayName: 'Australian CGT Frozen' },
    {
      key: 'australianCGTNonDiscount',
      displayName: 'Australian CGT Non Discount'
    },
    {
      key: 'australianCGTDiscount',
      displayName: 'Australian CGT Discount'
    },
    {
      key: 'australianCGTExcluded',
      displayName: 'Australian CGT Excluded'
    },
    {
      key: 'australianCGTExcludedPd0102',
      displayName: 'Australian CGT Excluded Pd 0102'
    },
    { key: 'foreignDividend', displayName: 'Foreign Dividend' },
    { key: 'foreignInterest', displayName: 'Foreign Interest' },
    { key: 'foreignOtherIncome', displayName: 'Foreign Other Income' },
    { key: 'foreignPreCGT', displayName: 'Foreign Pre CGT' },
    {
      key: 'foreignCGTFrozenIndex',
      displayName: 'Foreign CGT Frozen Index'
    },
    {
      key: 'foreignCGTNonDiscount',
      displayName: 'Foreign CGT Non Discount'
    },
    { key: 'foreignCGTDiscount', displayName: 'Foreign CGT Discount' },
    { key: 'foreignCGTExcluded', displayName: 'Foreign CGT Excluded' },
    {
      key: 'foreignCGTExcludedPd0102',
      displayName: 'Foreign CGT Excluded Pd 0102'
    },
    { key: 'taxDeferred', displayName: 'Tax Deferred' },
    {
      key: 'taxDeferredUnrealGain',
      displayName: 'Tax Deferred Unreal Gain'
    },
    { key: 'taxFree', displayName: 'Tax Free' },
    { key: 'imputationCredit', displayName: 'Imputation Credit' },
    { key: 'foreignTaxCredit', displayName: 'Foreign Tax Credit' },
    { key: 'modifiedPassive', displayName: 'Modified Passive' },
    { key: 'taxFreeEquityInfra', displayName: 'Tax Free Equity Infra' },
    { key: 'equityInfrastructure', displayName: 'Equity Infrastructure' },
    { key: 'returnOfCapital', displayName: 'Return Of Capital' },
    {
      key: 'foreignTaxCreditModPassive',
      displayName: 'Foreign Tax Credit Mod Passive'
    },
    {
      key: 'foreignTaxCreditInterest',
      displayName: 'Foreign Tax Credit Interest'
    },
    { key: 'licDividend', displayName: 'LIC', postProcessor: (cellData) => roundAndNormalise(cellData) },
    { key: 'nzTaxCredit', displayName: 'Nz Tax Credit' },
    { key: 'cfi', displayName: 'CFI' },
    { key: 'grossAustDivNzComp', displayName: 'Gross Aust Div Nz Comp' },
    { key: 'explorationCredits', displayName: 'Exploration Credits' },
    { key: 'netAmit', displayName: 'Net Amit' },
    { key: 'interestGovBond', displayName: 'Interest Gov Bond' },
    {
      key: 'foreignCapitalGainsTaxCredit',
      displayName: 'Foreign Capital Gains Tax Credit'
    },
    {
      key: 'foreignCapitalGainsTaxDiscount',
      displayName: 'Foreign Capital Gains Tax Discount'
    },
    // {key: 'buybackCaptComp', displayName: 'Buyback Capt Comp'},
    // {key: 'buybackTaxExcess', displayName: 'Buyback Tax Excess'},
    // {key: 'buybackSaleDate', displayName: 'Buyback Sale Date'},
    { key: 'taxRate', displayName: 'Tax Rate', postProcessor: toPercentage }
    //{key: 'userNotes', displayName: 'User Notes'}
  ];
  // The following keys will always be displayed regardless of the values:
  private alwaysDisplayKeys: string[] = ['taxReportDate', 'rate', 'payDate']; //reinvestmentCode
  //endregion

  // There may be multiple rows needing rendering in the table; each of which is called a "layer".
  dataLayers: any[] = [];

  constructor() {}

  ngOnInit(): void {
    this.refreshLayers();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refreshLayers();
  }

  /**
   * Compute this.dataLayers from this.data
   */
  private refreshLayers() {
    this.isOrDividend = this.dataIsOrDividend();
    if (this.dataIsStapledDividend()) {
      if (this.fields[0].key !== 'securityCode') {
        this.fields.unshift(
          { key: 'securityCode', displayName: 'Underlying Security Code' },
          { key: 'securityName', displayName: 'Underlying Security Name' }
        );
      }
      this.dataLayers = this.data.distributionComponent.map((item: any) => ({
        ...this.data, // need this to get access to some fields that are shared among all rows
        ...item
      }));
    } else if (this.dataIsOrDividend()) {
      this.numberOfChoices = this.data.distribution.length;
      this.dataLayers = this.data.distribution.map((item: any) => ({
        ...this.data, // need this to get access to some fields that are shared among all rows
        ...item
      }));
    } else if (this.dataIsAndDividend()) {
      this.dataLayers = this.data[0].map((item: any) => ({
        ...this.data, // need this to get access to some fields that are shared among all rows
        ...item
      }));
    } else {
      this.dataLayers = [this.data];
    }
  }

  /**
   * Returns whether a selected field should be displayed.
   * A field should only display if, for at least 1 layer:
   * 1. it's not nullish
   * 2. it's not empty, and
   * 3. it's not 0
   */
  shouldDisplayField(field: Field): boolean {
    if (this.alwaysDisplayKeys.includes(field.key)) {
      return true;
    }
    return this.dataLayers.some((layer) => {
      const data = layer[field.key];
      return data && data != '' && data != 0;
    });
  }

  /**
   * Returns whether the data is a stapled dividend
   * NOTE - as for now this is determined if there are multiple distribution components.
   * @private
   */
  private dataIsStapledDividend() {
    return Array.isArray(this.data.distributionComponent);
  }
  private dataIsOrDividend() {
    return Array.isArray(this.data.distribution);
  }
  private dataIsAndDividend() {
    return Array.isArray(this.data[0]);
  }

  displayCell(field: Field, layer: any) {
    const rawData = layer[field.key];

    if (field.postProcessor) {
      return field.postProcessor(rawData, layer);
    } else {
      return defaultCellProcessor(rawData, layer);
    }
  }
}

/**
 * Describes a field.
 * DisplayName: what will be displayed to the user.
 * key: the key to extract the data from the layer
 * postProcessor: if provided, the raw data will be passed through this before being put into DOM.
 *                Otherwise, the default post-processor will be used.
 */
interface Field {
  displayName: string;
  key: string;
  postProcessor?: PostProcessor;
}
