import { Component, Input, OnInit } from '@angular/core';
import { toLabelValuePairArray } from '../buyback-details-renderer/buyback-details-renderer.component';

@Component({
  selector: 'app-bonus-details-renderer',
  templateUrl: './bonus-details-renderer.component.html',
  styleUrls: ['./bonus-details-renderer.component.css']
})
export class BonusDetailsRendererComponent implements OnInit {
  @Input() details: any;

  constructor() {}

  ngOnInit(): void {}

  computeInfoboxData(): any {
    const data = this.details;
    return toLabelValuePairArray({
      'Ex Date': data.exDate,
      Code: data.code,
      'Book Close Date': data.bookCloseDate,
      'Issue Code': data.newCode,
      Ratio: `${data.numerator} for ${data.denominator}`
    });
  }
}
