import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TrustComponentService {
  constructor(private http: HttpClient) {}

  getTrustComponents = (startDate: Date | string, endDate: Date | string, isOutstanding: boolean) => {
    const queryParams = new URLSearchParams(JSON.parse(JSON.stringify({ startDate, endDate, isOutstanding })));
    return this.http.get(`api/distributionComponent/trust-components?${queryParams}`);
  };
}
