import { Component, Input, OnInit } from '@angular/core';
import { CGTViewAccordionData } from '../-models-/accordion-data.model';

@Component({
  selector: 'app-dashboard-accordion',
  templateUrl: './dashboard-accordion.component.html',
  styleUrls: ['./dashboard-accordion.component.css']
})
export class DashboardAccordionComponent implements OnInit {

  @Input() data!: CGTViewAccordionData;

  constructor() { }

  ngOnInit(): void {
  }

}
