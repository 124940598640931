import {Component, Input, OnInit} from '@angular/core';
import {ApportionmentDescription, ApportionmentEntry} from '../../../-models-/apportionment-data.model';

interface TableRowDescriptor {
  columns: string[];
  isEstimate: boolean;
}

@Component({
  selector: 'app-apportionment-entry',
  templateUrl: './apportionment-entry.component.html',
  styleUrls: ['./apportionment-entry.component.css']
})
export class ApportionmentEntryComponent implements OnInit {

  @Input() data!: ApportionmentEntry;
  columnHeaders!: string[]; // this is all the <th>s that describe columns in the table

  constructor() { }

  ngOnInit(): void {
    this.columnHeaders = this.generateTableColumns(this.data);
  }

  /**
   * Dynamically render one row of a table, by rowData.
   * @param descriptions An array of strings, representing the values for each cell left to right
   */
  renderTableRow(descriptions: ApportionmentDescription): TableRowDescriptor {

    const columns = this.generateEmptyRowArray(this.columnHeaders.length);

    // First two columns are known
    columns[0] = descriptions.periodStart;
    columns[1] = descriptions.periodEnd;

    // For each description, insert it into the table
    for (const desc of descriptions.description /* bad naming on the return values here; it's an array of label-value pairs */) {
      const index = this.getColumnIndexByLabel(desc.label, this.columnHeaders);
      if (index === -1) continue;
      columns[index] = this.toPercentageString(desc.value);
    }

    return {
      isEstimate: descriptions.isEstimate,
      columns
    };

  }

  // Dynamically get a list of table columns
  // based on unique "descriptions" inside this.data
  // First two columns are always "From" and "To".
  private generateTableColumns(data: ApportionmentEntry): string[] {

    const uniqueLabels = new Set<string>();
    for (const apportionment of data.apportionments) {
      for (const description of apportionment.description) {
        uniqueLabels.add(description.label);
      }
    }

    return ['From', 'To', ...uniqueLabels.values()];

  }

  // Gets the first column index that matches the label
  private getColumnIndexByLabel(label: string, columns: string[]): number {
    const result = columns.indexOf(label);
    if (result === -1) {
      console.warn(`Assumption violation: ${label} is not found in the columns data`);
    }
    return result;
  }

  private generateEmptyRowArray(length: number): string[] {
    const result = [];
    for (let i = 0; i < length; ++i) {
      result[i] = 'N/A';
    }
    return result;
  }

  // e.g. 0.3545 -> 35.45%
  private toPercentageString(num: number): string {
    if (num > 1) {
      console.warn('Assumption violation: provided number is more than 100%');
    }
    const percentage = (num * 100).toFixed(2);
    return `${percentage}%`;
  }

}
