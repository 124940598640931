// API interface for CGTView Dashboard

import { CgtvDashboardComponent } from './cgtv-dashboard.component';
import { ViewFilter } from './view-filter.model';
import { CGTViewAccordionData } from './-models-/accordion-data.model';
import { ApiHttpRequest } from '../../../api-connector/api-http-request';

export class CGTViewDashboardLoader {
  constructor(private host: CgtvDashboardComponent) {}

  async loadDashboardData(filter: ViewFilter): Promise<CGTViewAccordionData> {
    const { companyId, securityId, financialYear, reportType = 'All Activities' } = filter;
    return await ApiHttpRequest.loadDashboardAccordionData(companyId, securityId, financialYear, reportType);
  }
}
