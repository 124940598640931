import { Injectable } from '@angular/core';
import { DividendEntryViewModel } from './models/dividend-entry-view-model.model';
import { DividendEntry } from './models/dividend-entry.model';
import { ApiHttpRequest } from '../../../../api-connector/api-http-request';

@Injectable()
export class DividendCalculatorService {

  constructor() {
  }

  rawEntries: DividendEntry[] = [];
  entries: DividendEntryViewModel[] = [];
  globalUnits: number = 0;
  total: number = 123;

  // This should be called when the user edits the values field in one row
  setUnitsForEntry(units: number, entry: DividendEntryViewModel) {
    entry.units = units;
    this.calculateTotal();
  }

  async load(securityId: string, financialYear: string, units: number) {

    // set global unit
    this.globalUnits = units;

    // ask the server for information
    this.rawEntries = await ApiHttpRequest.getDividendActionList(securityId, financialYear);

    this.populateViewModels();
    this.calculateTotal();

  }

  // Populates this.entries from this.rawEntries.
  // Should only be called after the raw entries are first loaded
  private populateViewModels() {
    this.entries = this.rawEntries.map(e => this.getViewModelByRawEntry(e));
  }

  private calculateTotal() {
    this.total = this.entries.reduce((sum, entry) => sum + entry.total, 0);
  }

  private getViewModelByRawEntry(entry: DividendEntry): DividendEntryViewModel {
    return new DividendEntryViewModel({
      // static items
      units: this.globalUnits,
      payDate: entry.payDate,
      exDate: entry.exDate,
      activity: entry.activity,
      security: entry.security,
      rawEntryRef: entry,
      rate: entry.rate,
      itemId: entry.itemId,
      taxYear: entry.taxYear
    })
  }

}

