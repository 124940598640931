import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CGTViewDashboardLoader } from './loader';
import { CGTViewAccordionData } from './-models-/accordion-data.model';
import { SwalError } from '../../common/util/swal-mixins/swal-error';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DashboardFiltersComponent } from './dashboard-filters/dashboard-filters.component';
import { CGTVDashboardParamsParser } from './cgtv-dashboard-params.parser';
import { CoopActionDetailsService } from './coop-action-details.service';

@Component({
  selector: 'app-cgtv-dashboard',
  templateUrl: './cgtv-dashboard.component.html',
  styleUrls: ['./cgtv-dashboard.component.css'],
  providers: [CoopActionDetailsService]
})
export class CgtvDashboardComponent implements OnInit, OnDestroy {
  // States
  state: 'invalid' | 'loading' | 'loaded' = 'invalid';
  accordionData: CGTViewAccordionData | null = null;

  // View Children
  @ViewChild('filtersComponent') filters!: DashboardFiltersComponent;

  // Delegates
  private loader = new CGTViewDashboardLoader(this);
  private searchParamsParser = new CGTVDashboardParamsParser(this, this.route, this.router);

  // internals
  private routeChangeSubscription!: Subscription;

  // methods
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.routeChangeSubscription = this.route.queryParams.subscribe(this.onRouteChanged.bind(this));
  }

  ngOnDestroy(): void {
    if (this.routeChangeSubscription) this.routeChangeSubscription.unsubscribe();
  }

  async onRouteChanged() {
    const searchParams = this.searchParamsParser.parseSearchParams();

    if (!searchParams) {
      this.state = 'invalid';
      return;
    }

    try {
      this.state = 'loading';
      this.accordionData = await this.loader.loadDashboardData(searchParams);
      this.state = 'loaded';
    } catch (e) {
      console.error(e);
      await SwalError.fire('Cannot load data: ' + e);
      this.state = 'invalid';
    }
  }
}
