import { Component, OnDestroy, OnInit } from '@angular/core';
import { DividendCalculatorService } from '../../-services-/dividend-calculator.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DCSearchParamCodec } from '../../search-params/dc-search-param-codec';

@Component({
  selector: 'app-dividend-calculator-table-container',
  templateUrl: './dividend-calculator-table-container.component.html',
  styleUrls: ['./dividend-calculator-table-container.component.css']
})
export class DividendCalculatorTableContainerComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  constructor(private calcService: DividendCalculatorService,
              private activatedRoute: ActivatedRoute) { }

  state: 'Invalid' | 'Loading' | 'Loaded' | 'Error' = 'Loaded';

  ngOnInit(): void {
    const sub = this.activatedRoute.queryParams.subscribe(() => this.onSearchParamChange());
    this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  async onSearchParamChange() {

    const params = this.activatedRoute.snapshot.queryParams;
    let args: {securityId: string, financialYear: string, units: number};

    try {
      args = new DCSearchParamCodec().decodeSearchParams(params);
    } catch (e) {
      this.state = 'Invalid';
      return;
    }

    try {
      this.state = 'Loading';
      await this.calcService.load(args.securityId, args.financialYear, args.units);
      this.state = 'Loaded';
    } catch (e) {
      this.state = 'Error';
      throw e;
    }

  }

}
