import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FieldDirective } from './field.directive';
import { CoopActionDetailComponentClassResolver } from './coop-action-detail-component-class-resolver';
import { DocumentLink } from './models/document-link.model';
import { CorporateAction } from 'src/api-connector/api-collections/cgt-view/cgt-view.types';
import { DISCLAIMER_MSG } from 'src/app/app.constants';

@Component({
  selector: 'app-corporate-action-details',
  templateUrl: './corporate-action-details.component.html',
  styleUrls: ['./corporate-action-details.component.css']
})
export class CorporateActionDetailsComponent implements OnChanges {
  @Input() activityDetails: any;
  @Input() activityType!: string;
  @Input() coopAction!: CorporateAction;

  @ViewChild(FieldDirective, { static: true }) field!: FieldDirective;

  disclaimerMsg = DISCLAIMER_MSG;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    this.generateAndRenderDataComponent();
  }

  private generateAndRenderDataComponent() {
    const classToInstantiate = CoopActionDetailComponentClassResolver.resolve(this.coopAction, this.activityDetails);

    const viewContainerRef = this.field.viewContainerRef;
    viewContainerRef.clear();
    const newComponentRef = viewContainerRef.createComponent(classToInstantiate);

    // @ts-ignore
    newComponentRef.instance.details = this.activityDetails;
  }

  getSourceDocuments(): DocumentLink[] {
    const rawSourceArray: { title: string; source: string; icon?: 'File' | 'Folder' | 'PDF' }[] = this.getRawSourceArray();
    return rawSourceArray.map((item) => ({
      icon: item.icon || 'File',
      url: item.source,
      label: item.title
    }));
  }

  /**
   * After flattenation, this.details.source might be an array or a single object.
   * This function detects whether it is a single object, and wrap it in an array if it is,
   * to prevent later type confusion.
   * @private
   */
  private getRawSourceArray() {
    const source = this.activityDetails.sourceDocuments;
    if (Array.isArray(source)) {
      return source;
    } else {
      if (this.activityDetails.title && this.activityDetails.source && this.activityDetails.fileType) {
        return [
          {
            title: this.activityDetails.title, // title would have been flattened into the main object
            source: this.activityDetails.source,
            fileType: this.activityDetails.fileType
          }
        ];
      } else {
        return [];
      }
    }
  }

  getATORulingDocumentLinks(): DocumentLink[] {
    if (Array.isArray(this.activityDetails.atoRulingsDocuments)) {
      return this.activityDetails.atoRulingsDocuments.map((rulingDocument: any) => {
        return {
          icon: 'PDF',
          label: rulingDocument.title,
          url: rulingDocument.source
        };
      });
    }
    return [];
  }
}
