// This class takes in a coop action name and returns a component that renders this particular type of coop actions.

import { Component } from '@angular/core';
import { DividendDetailsRendererComponent } from './concrete-types/dividend-details-renderer/dividend-details-renderer.component';
import { BuybackDetailsRendererComponent } from './concrete-types/buyback-details-renderer/buyback-details-renderer.component';
import { DemergerDetailsRendererComponent } from './concrete-types/demerger-details-renderer/demerger-details-renderer.component';
import { MergeDetailsRendererComponent } from './concrete-types/merge-details-renderer/merge-details-renderer.component';
import { BonusDetailsRendererComponent } from './concrete-types/bonus-details-renderer/bonus-details-renderer.component';
import { SPPDetailsRendererComponent } from './concrete-types/sppdetails-renderer/sppdetails-renderer.component';
import { OptionExpiryDetailsRendererComponent } from './concrete-types/option-expiry-details-renderer/option-expiry-details-renderer.component';
import { RightsIssueDetailsRendererComponent } from './concrete-types/rights-issue-details-renderer/rights-issue-details-renderer.component';
import { UnknownTypeDetailsRendererComponent } from './concrete-types/unknown-type-details-renderer/unknown-type-details-renderer.component';
import { DelistingDetailsRendererComponent } from './concrete-types/delisting-details-renderer/delisting-details-renderer.component';
import { InfoDetailsRendererComponent } from './concrete-types/info-details-renderer/info-details-renderer.component';
import { ReconstructionDetailsRendererComponent } from './concrete-types/reconstruction-details-renderer/reconstruction-details-renderer.component';
import { SchemaArrangementRendererComponent } from './concrete-types/schema-arrangement-renderer/schema-arrangement-renderer.component';
import { CorporateAction } from 'src/api-connector/api-collections/cgt-view/cgt-view.types';

export class CoopActionDetailComponentClassResolver {
  static readonly typesRegistry: Record<string, typeof CoopActionDetailsComponent> = {
    '0': DemergerDetailsRendererComponent,
    '1': ReconstructionDetailsRendererComponent,
    '2': BonusDetailsRendererComponent,
    '3': DividendDetailsRendererComponent,
    '3_4': BuybackDetailsRendererComponent,
    '7': SPPDetailsRendererComponent,
    '10': MergeDetailsRendererComponent,
    '15': OptionExpiryDetailsRendererComponent,
    '13': RightsIssueDetailsRendererComponent,
    '20': SchemaArrangementRendererComponent,
    '39': InfoDetailsRendererComponent,
    '41': DelistingDetailsRendererComponent
  };

  static resolve(coopAction: CorporateAction, details: any): typeof CoopActionDetailsComponent {
    const processedType = processType(coopAction);
    if (!processedType) {
      return UnknownTypeDetailsRendererComponent;
    }
    return this.typesRegistry[processedType] ?? UnknownTypeDetailsRendererComponent;
  }
}

export class CoopActionDetailsComponent extends Component {
  details: any;
}

/**
 * Process a type by turning it into lower case and removing all whitespace and whitespace-like characters.
 * @param type
 */
function processType(coopAction: CorporateAction): string | null {
  if (coopAction.isBuyback) {
    // created by concating coopActionId of dividend and displayTypeId of buyback
    return '3_4';
  } else {
    return coopAction.coopActionTypeId;
  }
}
