import { DividendCalculatorSearchParams, isValidDivCalcSearchParams } from './dividend-calculator-search-params.model';

export class DCSearchParamCodec {

    /**
     * Construct a search param dictionary from FormGroup value
     * @param formValue
     */
    encodeSearchParams(formValue: any): DividendCalculatorSearchParams {
      return {
            securityId: formValue.estb.id,
            financialYear: formValue.financialYear,
            units: formValue.units.toString()
        };
    }

    /**
     * Parse a search param dict back into FormGroup value
     * Throws an exception if it's not valid
     * @param params
     */
    decodeSearchParams(params: any) {
        if (!isValidDivCalcSearchParams(params)) throw new Error('Params are invalid');
        return {
            securityId: params.securityId,
            financialYear: params.financialYear,
            units: parseInt(params.units)
        };
    }

}
