import { Component, Input, OnInit } from '@angular/core';
import { toLabelValuePairArray } from '../buyback-details-renderer/buyback-details-renderer.component';
import { LabelValuePair } from '../../../../../../api-connector/api-collections/cgt-view/cgt-view.types';
import { roundAndNormalise } from '../../common-components/components-table/processors/round-and-normalise';

@Component({
  selector: 'app-sppdetails-renderer',
  templateUrl: './sppdetails-renderer.component.html',
  styleUrls: ['./sppdetails-renderer.component.css']
})
export class SPPDetailsRendererComponent implements OnInit {
  @Input() details: any;

  constructor() {}

  ngOnInit(): void {}

  computeInfoboxData(): LabelValuePair[] {
    const data = this.details;
    return toLabelValuePairArray({
      'Issue Date': data.executionDate,
      Code: data.securityCode,
      'Announcement Date': data.announcementDate,
      'Applications Close Date': data.applicationCloseDate,
      'Book Close Date': data.bookCloseDate,
      'Despatch Date': data.despatchDate,
      'SPP Price': '$' + roundAndNormalise(data.marketValue, 6),
      'Minimum Amount': '$' + roundAndNormalise(data.minimumAmount, 6),
      'Maximum Amount': '$' + roundAndNormalise(data.maximumAmount, 6)
    });
  }
}
