import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DocumentLink } from '../../models/document-link.model';
import { toLabelValuePairArray } from '../buyback-details-renderer/buyback-details-renderer.component';

@Component({
  selector: 'app-info-details-renderer',
  templateUrl: './info-details-renderer.component.html',
  styleUrls: ['./info-details-renderer.component.css']
})
export class InfoDetailsRendererComponent implements OnInit {
  @Input() details: any;

  constructor() {}

  ngOnInit(): void {}

  /**
   * Get label-value pairs for the top infobox.
   */
  computeInfoboxEntries() {
    if (!this.details) {
      return;
    }

    const details = this.details;
    return toLabelValuePairArray({
      'Ex Date': details.exDate,
      Code: details.securityCode
    });
  }
}
