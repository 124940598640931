import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CompanyDetailsData } from '../../-models-/company-details.model';

@Component({
  selector: 'app-company-details-tab',
  templateUrl: './company-details-tab.component.html',
  styleUrls: ['./company-details-tab.component.css']
})
export class CompanyDetailsTabComponent implements OnInit, OnChanges {

  @Input() companyDetailsData!: CompanyDetailsData;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.formatRegistryPhoneData();
  }

  /**
   * Look for "phone" or "fax" in labels among the registry data.
   * If it matches, replace the value with parsed international format phone number.
   * @private
   */
  private async formatRegistryPhoneData() {

    const arr = this.companyDetailsData.registryDetails;

    for (const entry of arr) {
      if (/(fax|phone)/gi.test(entry.label)) {
        entry.value = await formatPhoneNumber(entry.value);
      }
    }

  }

}

async function formatPhoneNumber(str: string): Promise<string> {

  const {PhoneNumberUtil} = await import(/* webpackChunkName: "lib-phone-number" */'google-libphonenumber');

  try {
    const phoneNumberUtilInstance = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneNumberUtilInstance.parse(str, 'AU');

    if (phoneNumberUtilInstance.isPossibleNumber(parsedNumber)) {
      return phoneNumberUtilInstance.format(parsedNumber, 1 /*PhoneNumberFormat.INTERNATIONAL*/);
    } else {
      return str;
    }
  } catch (e) {
    console.warn(`Cannot parse phone number "${str}"`);
    return str;
  }

}
