import { Component, Input, OnInit } from '@angular/core';
import { DocumentLink } from '../../models/document-link.model';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {
  @Input({ required: false }) title = '';
  @Input({ required: false }) data: DocumentLink[] = [];

  constructor() {}

  ngOnInit(): void {}

  get hasDocs() {
    return this.data?.length;
  }
}
