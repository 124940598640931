import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { toLabelValuePairArray } from '../buyback-details-renderer/buyback-details-renderer.component';
import { LabelValuePair } from '../../../../../../api-connector/api-collections/cgt-view/cgt-view.types';

@Component({
  selector: 'app-delisting-details-renderer',
  templateUrl: './delisting-details-renderer.component.html',
  styleUrls: ['./delisting-details-renderer.component.css']
})
export class DelistingDetailsRendererComponent implements OnInit, OnChanges {
  @Input() details: any;

  infoboxData!: LabelValuePair[];

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    this.computeInfoboxData();
  }

  ngOnInit(): void {
    this.computeInfoboxData();
  }

  private computeInfoboxData() {
    this.infoboxData = toLabelValuePairArray({
      'Ex Date': this.details.executionDate,
      Code: this.details.securityCode
    });
  }
}
