import { Component, Input, OnInit } from '@angular/core';
import { DocumentLink } from '../../models/document-link.model';

@Component({
  selector: 'app-document-link',
  templateUrl: './document-link.component.html',
  styleUrls: ['./document-link.component.css']
})
export class DocumentLinkComponent implements OnInit {
  @Input() data!: DocumentLink;

  getIconClassByData(itemObj: DocumentLink): string {
    switch (itemObj.icon) {
      case 'File':
        return 'pi-file';
      case 'PDF':
        return 'pi-file-pdf';
      case 'Folder':
        return 'pi-folder-open';
      default:
        return 'pi-file';
    }
  }

  constructor() {}

  ngOnInit(): void {}
}
