import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DCSearchParamCodec } from '../../search-params/dc-search-param-codec';
// import {
//   EstablishmentSearchComponent
// } from '../../../-components-/establishment-search/establishment-search.component';

@Component({
  selector: 'app-dividend-calculator-filters',
  templateUrl: './dividend-calculator-filters.component.html',
  styleUrls: ['./dividend-calculator-filters.component.css']
})
export class DividendCalculatorFiltersComponent implements OnInit {
  // @ViewChild('estbSearchComponent') estbSearch!: EstablishmentSearchComponent;

  estbControl = new UntypedFormControl(null, Validators.required);
  fyControl = new UntypedFormControl('FY21', Validators.required);
  unitControl = new UntypedFormControl(1, Validators.required);

  // delegates
  private spHandler = new DCSearchParamCodec();

  formGroup = new UntypedFormGroup({
    estb: this.estbControl,
    financialYear: this.fyControl,
    units: this.unitControl
  });

  constructor(private router: Router, public route: ActivatedRoute) {}

  ngOnInit(): void {
    this.checkActivatedSearchParams();
  }

  onConfirm() {
    if (this.formGroup.valid) {
      this.updateRouterQueryParams();
    }
  }

  onReset() {
    this.formGroup.reset();
  }

  private updateRouterQueryParams() {
    const params = this.spHandler.encodeSearchParams(this.formGroup.value);
    this.router.navigate(['./'], {
      relativeTo: this.route,
      queryParams: params
    });
  }

  private checkActivatedSearchParams() {
    try {
      const decoded = this.spHandler.decodeSearchParams(this.route.snapshot.queryParams);
      this.fyControl.patchValue(decoded.financialYear);
      this.unitControl.patchValue(decoded.units);
      // this.estbControl will update on its own
    } catch (ignored) {
      // it's invalid. don't do anything...
    }
  }
}
