<table>
  <tbody>
    <tr>
      <th>Code</th>
      <td>{{details.securityCode}}</td>
    </tr>
    <tr>
      <th>Date</th>
      <td>{{details.executionDate ?? details.exDate}}</td>
    </tr>
    <tr>
      <th>Description</th>
      <td><app-description-htmlrenderer [html]="details.description"></app-description-htmlrenderer></td>
    </tr>
  </tbody>
</table>
