import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-unknown-type-details-renderer',
  templateUrl: './unknown-type-details-renderer.component.html',
  styleUrls: ['./unknown-type-details-renderer.component.css']
})
export class UnknownTypeDetailsRendererComponent implements OnInit {
  @Input() details: any;

  constructor() {}

  ngOnInit(): void {}
}
