import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { toLabelValuePairArray } from '../buyback-details-renderer/buyback-details-renderer.component';
import { addDollarSign } from '../../common-components/components-table/processors/add-dollar-sign';

@Component({
  selector: 'app-reconstruction-details-renderer',
  templateUrl: './reconstruction-details-renderer.component.html'
})
export class ReconstructionDetailsRendererComponent implements OnInit {
  @Input() details: any;

  constructor() {}

  ngOnInit(): void {}

  /**
   * Get label-value pairs for the top infobox.
   */
  computeInfoboxEntries() {
    if (!this.details) {
      return;
    }

    const data = this.details;
    return toLabelValuePairArray({
      'Ex Date': data.executionDate,
      Code: data.securityCode,
      'New Code': data.newSecurityCode,
      Ratio: `${data.numerator} for ${data.denominator}`,
      Capital: addDollarSign(data.capital, 6),
      Premium: addDollarSign(data.premium, 6),
      'Price Prior': addDollarSign(data.priceBefore, 6),
      'Price After': addDollarSign(data.priceAfter, 6)
    });
  }
}
