import { Component } from '@angular/core';
import { ReleaseNotesService } from './release-notes.service';
import { MessageService } from 'primeng/api';
import { ICalenderEvent } from 'src/app/common/calender/calender.types';
import { fetchErrorMsg } from 'src/app/reports/reports.utilities';
import { IReleaseNotes } from './release-notes.types';
import { Table } from 'primeng/table';
import dayjs from 'dayjs';

const containsTxt = (inputText: string[], searchText: string) => {
  const inputValue = inputText.join('<>').toLowerCase();
  return inputValue?.toLowerCase().includes(searchText?.toLowerCase());
};

@Component({
  selector: 'release-notes-page',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.css'],
  providers: [ReleaseNotesService]
})
export class ReleaseNotesComponent {
  isLoading: boolean = false;
  isLoadingReport: boolean = false;
  data: IReleaseNotes[] = [];
  filteredData: IReleaseNotes[] = [];

  searchText: string | undefined = '';
  fileType = 'html';
  fromDate = '';
  toDate = '';
  hasDateError = false;

  constructor(private notesService: ReleaseNotesService, private messageService: MessageService) {}

  onClearSearch = () => {
    this.searchText = '';
    this.onSearchFilter();
  };

  onSearchFilter = () => {
    this.filteredData = this.data.filter((item) =>
      containsTxt([item.securityCode, item.securityName, item.description, item.caId], this.searchText || '')
    );
  };

  clear(table: Table) {
    table.clear();
  }

  onChangeDates = ({ fromDate, toDate }: ICalenderEvent) => {
    this.fromDate = fromDate;
    this.toDate = toDate;
    const dateDiff = dayjs(toDate).diff(dayjs(fromDate), 'year', true);
    this.hasDateError = dateDiff > 1;
  };

  private updateLoading = (isReport = false, value = false) => {
    if (isReport) {
      this.isLoadingReport = value;
    } else {
      this.isLoading = value;
    }
  };

  getReleaseNotes = (isReport = false) => {
    this.updateLoading(isReport, true);
    const fileType = isReport ? this.fileType : '';
    this.notesService.generateNotes(this.fromDate, this.toDate, fileType).subscribe({
      next: (res) => {
        if (isReport) {
          this.showNotification(isReport);
        } else {
          const notes = (res as IReleaseNotes[]) || [];
          this.data = notes.map((item) => ({ ...item, exDate: item.exDate ? dayjs(item.exDate, 'DD/MM/YYYY').toDate() : item.exDate }));
          this.onSearchFilter();
        }
        this.updateLoading(isReport, false);
      },
      error: (err) => this.showNotification(isReport, fetchErrorMsg(err))
    });
  };

  showNotification = (isReport = false, error = '') => {
    this.updateLoading(isReport, false);
    const notificationObj = {
      severity: 'success',
      summary: `Release notes is being generated in background`,
      detail: `You'll receive a notification once file is ready`,
      closable: true
    };
    if (error) {
      notificationObj.severity = 'error';
      notificationObj.summary = `Failed to generate release-notes`;
      notificationObj.detail = `${error}. Please try again later`;
    }
    this.messageService.add(notificationObj);
  };
}
