import { Component, Input, OnInit } from '@angular/core';
import { toLabelValuePairArray } from '../buyback-details-renderer/buyback-details-renderer.component';
import { round } from 'lodash-es';
import { LabelValuePair } from '../../../../../../api-connector/api-collections/cgt-view/cgt-view.types';
import { roundAndNormalise } from '../../common-components/components-table/processors/round-and-normalise';

@Component({
  selector: 'app-rights-issue-details-renderer',
  templateUrl: './rights-issue-details-renderer.component.html',
  styleUrls: ['./rights-issue-details-renderer.component.css']
})
export class RightsIssueDetailsRendererComponent implements OnInit {
  @Input() details: any;

  ngOnInit(): void {}

  computeInfoboxData(): LabelValuePair[] {
    const details = this.details;
    return toLabelValuePairArray({
      'Ex Date': details.executionDate,
      Code: details.code,
      'Book Close Date': details.bookCloseDate,
      'App. Close Date': details.applicationCloseDate,
      'App. Price': '$' + roundAndNormalise(details.marketValue, 6),
      Ratio: `${details.numerator} for ${details.denominator}`,
      'Right Code': details.rightCode,
      'New Code': details.newCode,
      Renouncable: details.renounceable ? 'Yes' : 'No',
      ...this.constructPossibleATORulingFragment()
    });
  }

  /**
   * Potentially returns a record of ATO ruling, or nothing if ato rulings is empty.
   * Output is intended for use with object.assign or ES6 spread (recommended).
   * @private
   */
  private constructPossibleATORulingFragment(): Record<string, string> {
    const rawAtoRulings = this.details.atoRulings;

    if (typeof rawAtoRulings == 'string' && rawAtoRulings.trim().length > 0) {
      return { 'ATO Rulings': rawAtoRulings };
    } else {
      return {};
    }
  }
}
