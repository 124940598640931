import Swal from 'sweetalert2';

export const SwalError = Swal.mixin({
  icon: 'error',
  title: 'Error'
});

/**
 * Shows an error message with sweet alert.
 * @param e - the error object. This fn will try to intelligently handle it.
 */
export async function showErrorWithSwal(e: any) {
  const message = e.message ?? e.errorMessage ?? e.error ?? JSON.stringify(e);
  await SwalError.fire(message.toString());
}
