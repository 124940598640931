import { Component, Input, OnInit } from '@angular/core';
import { toLabelValuePairArray } from '../buyback-details-renderer/buyback-details-renderer.component';
import { addDollarSign } from '../../common-components/components-table/processors/add-dollar-sign';

@Component({
  selector: 'app-dividend-details-renderer',
  templateUrl: './dividend-details-renderer.component.html',
  styleUrls: ['./dividend-details-renderer.component.css']
})
export class DividendDetailsRendererComponent implements OnInit {
  @Input() details: any;

  constructor() {}

  ngOnInit(): void {}

  getDrpBspValue(priceValue: number | null | string) {
    if (Number(priceValue) === 0) {
      return 'TBC';
    }
    if (!priceValue) {
      return null;
    }

    return addDollarSign(priceValue);
  }

  computeInfoboxData() {
    const drpPriceValue = this.getDrpBspValue(this.details.drpPrice);
    const bspPriceValue = this.getDrpBspValue(this.details.bspPrice);

    return toLabelValuePairArray({
      'Ex Date': this.details.exDate,
      Code: this.details.securityCode,
      ...(drpPriceValue && { 'DRP Price': drpPriceValue }),
      ...(bspPriceValue && { 'BSP Price': bspPriceValue }),
      'Reinvestment Code': this.details.reinvestmentCode
    });
  }
}
