import { Component, Input, OnInit } from '@angular/core';
import { toLabelValuePairArray } from '../buyback-details-renderer/buyback-details-renderer.component';

@Component({
  selector: 'app-option-expiry-details-renderer',
  templateUrl: './option-expiry-details-renderer.component.html',
  styleUrls: ['./option-expiry-details-renderer.component.css']
})
export class OptionExpiryDetailsRendererComponent implements OnInit {
  @Input() details: any;

  constructor() {}

  ngOnInit(): void {}

  computeInfoboxData() {
    return toLabelValuePairArray({
      'Expiry Date': this.details.executionDate,
      Code: this.details.securityCode
    });
  }
}
