// Shape of the dividend calculator search params
export interface DividendCalculatorSearchParams {
  [index: string]: string;
  securityId: string;
  financialYear: string;
  units: string; /* This will have to be stringified in the url; remember to parse it */
}

export function isValidDivCalcSearchParams(object: any) {
  const keys = ['securityId', 'financialYear', 'units'];
  return keys.every(key => {
    return object.hasOwnProperty(key)
  })
}
