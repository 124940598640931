import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Component({
  selector: 'app-note-section',
  templateUrl: './note-section.component.html',
  styleUrls: ['./note-section.component.css']
})
export class NoteSectionComponent implements OnInit {
  @Input() title?: string;
  @Input() html!: string;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  getProcessedHTML(): SafeHtml {
    const html = this.html.replace(/\{([\d\w]{1,6}):(\d+)\}/giu, (str, securityCode, securityId) => {
      return `<a data-security-id="${securityId}" href="${generateLinkForSecurityId(securityId)}">${securityCode}</a>`;
    });
    return this.sanitizer.bypassSecurityTrustHtml(DOMPurify.sanitize(html?.replace(/<P>&nbsp;<\/P>/gi, '')));
  }
}

/**
 * Generate a HTTP link to a particular security ID
 * TODO move this somewhere more suitable
 * @param securityId
 */
export function generateLinkForSecurityId(securityId: string) {
  return `/cgtview?securityId=${securityId}`;
}
