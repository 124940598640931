<div class="grid" [formGroup]="formGroup">
  <div class="col-4">
    <!-- <app-establishment-search
      [formControl]="estbControl"
      [prefillSecurityId]="route.snapshot.queryParams.securityId"
      #estbSearchComponent
    ></app-establishment-search> -->
  </div>

  <div class="col-3">
    <!-- <app-fy-select [formControl]='fyControl' [allowAll]="false" ngDefaultControl></app-fy-select> -->
  </div>

  <div class="col-3 flex flex-column">
    <div>Units</div>
    <p-inputNumber [formControl]="unitControl" [showButtons]="true" [min]="1"></p-inputNumber>
  </div>

  <div class="col-2 p-buttonset do-translate">
    <button pButton class="p-button-outlined p-button-primary p-fluid" label="Confirm" (click)="onConfirm()" [disabled]="!formGroup.valid"></button>
    <button pButton class="p-button-outlined p-button-danger" label="Reset" (click)="onReset()" [disabled]="!formGroup.touched"></button>
  </div>
</div>
