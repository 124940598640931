<div class='grid'>
  <div class='col-12'>
    <app-dashboard-filters #filtersComponent></app-dashboard-filters>
  </div>
  <div class='col-12'>
    <app-notice-card icon='pi pi-search' content='Search for a security above' *ngIf='state === "invalid"'></app-notice-card>
    <app-notice-card icon='pi pi-spin pi-spinner' content='Loading' *ngIf='state === "loading"'></app-notice-card>
    <app-dashboard-accordion [data]='accordionData!' *ngIf='state === "loaded"'></app-dashboard-accordion>
  </div>
</div>
<app-coop-action-popup></app-coop-action-popup>
