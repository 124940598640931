// A special australian franked processor
// See: https://estm.atlassian.net/browse/CGT-111

import {PostProcessor} from "./processors";
import {defaultCellProcessor} from "./default-processor";
import {round} from "lodash-es";

export const AustralianFrankedProcessor: PostProcessor = (cellData, layer) => {

  const numericComponent = defaultCellProcessor(cellData, layer);

  const percentage = (layer.australianFranked - Math.abs(layer.imputationCredit)) * 100;
  const percentageRounded = round(percentage, 2);

  return `${numericComponent} (${percentageRounded}%)`;

};
