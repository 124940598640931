<p-table [resizableColumns]="true" [value]='dcService.entries'>

  <ng-template pTemplate='header'>
    <tr>
      <th pResizableColumn>Security</th>
      <th pResizableColumn>Ex Date</th>
      <th pResizableColumn>Units</th>
      <th pResizableColumn>Activity</th>
      <th pResizableColumn>Pay Date</th>
      <th pResizableColumn>Tax Year</th>
      <th pResizableColumn>Rate</th>
      <th pResizableColumn>Total</th>
      <th pResizableColumn>Australia Gross Franked</th>
      <th pResizableColumn>Imputation Credit</th>
      <th pResizableColumn>Australia Net Franked</th>
    </tr>
  </ng-template>

  <ng-template pTemplate='body' let-item>
    <tr>
      <td>{{ item.security }}</td>
      <td>{{ item.exDate }}</td>
      <td>
        <app-dividend-calculator-individual-entry-units-input
          [item]='item'
        ></app-dividend-calculator-individual-entry-units-input>
      </td>
      <td>{{ item.activity }}</td>
      <td>{{ item.payDate }}</td>
      <td>{{ item.taxYear }}</td>
      <td>{{ item.rate }}</td>
      <td>{{ item.total | currency }}</td>
      <td>{{ item.ausGrossFranked | currency }}</td>
      <td>{{ item.imputationCredit | currency }}</td>
      <td>{{ item.ausNetFranked | currency }}</td>
    </tr>
  </ng-template>

  <ng-template pTemplate='summary'>
    Total: {{ dcService.total | currency }}
  </ng-template>

</p-table>
