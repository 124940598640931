<div class='grid'>

  <!-- Company Details -->
  <div class='p-card col-12 p-md-6 p-lg-6 p-2'>
    <app-infobox-table [data]='companyDetailsData.companyDetails'></app-infobox-table>
  </div>

  <!-- Registry Details -->
  <div class='p-card col-12 p-md-6 p-lg-6 p-2'>
    <app-infobox-table [data]='companyDetailsData.registryDetails'></app-infobox-table>
  </div>

  <!-- Company History -->
  <div class='p-card col-12 p-2'>
    <p-table [resizableColumns]="true" [value]='companyDetailsData.companyHistoryData' styleClass='p-datatable-sm p-datatable-gridlines'>
      <ng-template pTemplate='header'>
        <tr>
          <th pResizableColumn style='width: 20%'>Date</th>
          <th pResizableColumn>Change</th>
        </tr>
      </ng-template>
      <ng-template pTemplate='body' let-entry>
        <tr>
          <td>{{ entry.label }}</td>
          <td>{{ entry.value }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

</div>
