<div class="container">
  <div class="filters">
    <div class="calender-container">
      <app-fy-calender label="Date Range" [isMandatory]="true" (onChange)="onChangeDates($event)" />
    </div>

    <span class="p-float-label p-input-icon-right">
      <i class="pi pi-times" *ngIf="(searchText || '').length > 0" (click)="onClearSearch()"></i>
      <input
        type="text"
        pInputText
        [(ngModel)]="searchText"
        (blur)="onSearchFilter()"
        (keyup.enter)="onSearchFilter()"
        placeholder="Enter to search"
      />
      <label>Search Notes</label>
    </span>

    <p-button
      [style]="{ height: '100%' }"
      [disabled]="!(isLoading || (fromDate && toDate && !hasDateError))"
      label="Get Release Notes"
      [loading]="isLoading"
      (onClick)="getReleaseNotes()"
    ></p-button>

    <span style="flex: 1"></span>

    <p-button
      [style]="{ height: '100%' }"
      [disabled]="!(isLoadingReport || (fromDate && toDate && !hasDateError))"
      label="Generate HTML Report"
      [loading]="isLoadingReport"
      (onClick)="getReleaseNotes(true)"
    ></p-button>
  </div>
  <div>
    <span *ngIf="hasDateError" style="color: red"> Date range should be within 1 year </span>
  </div>

  <div>
    <p-table
      [value]="filteredData"
      dataKey="id"
      styleClass="p-datatable-striped"
      [scrollable]="true"
      scrollHeight="500px"
      [resizableColumns]="true"
      [paginator]="true"
      [rows]="50"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[25, 50, 100]"
    >
      <ng-template pTemplate="header">
        <tr>
          <th pResizableColumn style="width: 5rem"></th>
          <th pResizableColumn class="columnWidth" pSortableColumn="securityCode">Security Code <p-sortIcon field="securityCode"></p-sortIcon></th>
          <th pResizableColumn class="columnWidthRemaining" pSortableColumn="securityName">
            Security Name <p-sortIcon field="securityName"></p-sortIcon>
          </th>
          <th pResizableColumn class="columnWidthRemaining" pSortableColumn="description">
            Description <p-sortIcon field="description"></p-sortIcon>
          </th>
          <th pResizableColumn class="columnWidth" pSortableColumn="exDate">Ex Date <p-sortIcon field="exDate"></p-sortIcon></th>
          <th pResizableColumn class="columnWidth" pSortableColumn="caId">CA ID <p-sortIcon field="caId"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-expanded="expanded">
        <tr>
          <td>
            <button
              type="button"
              pButton
              pRipple
              [pRowToggler]="item"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            ></button>
          </td>
          <td class="columnWidth">{{ item.securityCode }}</td>
          <td class="columnWidthRemaining">{{ item.securityName }}</td>
          <td class="columnWidthRemaining">
            <app-description-htmlrenderer [html]="item.description"></app-description-htmlrenderer>
          </td>
          <td class="columnWidth">{{ item.exDate | appDate }}</td>
          <td class="columnWidth">{{ item.caId }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-item>
        <tr>
          <td [attr.colspan]="6">
            <div class="notes-container">
              <div *ngIf="item.notes?.length > 0; else noNotesFound">
                <app-description-htmlrenderer [html]="item.notes"></app-description-htmlrenderer>
              </div>
              <ng-template #noNotesFound> No Notes found </ng-template>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="6" style="text-align: center" class="text-secondary">No release notes found</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
