<app-infobox-table [data]="computeInfoboxData()"></app-infobox-table>

<table class="mt-3 mb-3">
  <thead>
    <tr>
      <th>Ex Date</th>
      <th>New Code</th>
      <th>App. Price</th>
      <th>Ratio</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ details.executionDate }}</td>
      <td>{{ details.newCode }}</td>
      <td>{{ details['0'] }}</td>
      <td>{{ details.ratio }}</td>
    </tr>
  </tbody>
</table>
