import { Component, ViewChild } from '@angular/core';
import { TrustComponentService } from './trust-components.service';
import { MessageService } from 'primeng/api';
import { ITrustComponents } from './trust-components.types';
import { Table } from 'primeng/table';
import { getTaxYears, ITaxYear } from 'src/app/common/calender/tax-year.constants';

const containsTxt = (inputText: string[], searchText: string) => {
  const inputValue = inputText.join('<>').toLowerCase();
  return inputValue?.toLowerCase().includes(searchText?.toLowerCase());
};

@Component({
  selector: 'trust-components-page',
  templateUrl: './trust-components.component.html',
  styleUrls: ['./trust-components.component.css'],
  providers: [TrustComponentService]
})
export class TrustSecurityComponent {
  @ViewChild('dt') table!: Table;

  isLoading: boolean = false;
  data: ITrustComponents[] = [];
  filteredData: ITrustComponents[] = [];

  searchText: string | undefined = '';
  financialYear!: ITaxYear;
  taxYears: ITaxYear[] = [];
  isOutstanding = false;
  statusOptions: any[] = [
    { label: 'Completed', value: false },
    { label: 'Outstanding', value: true }
  ];
  securityId = '';

  columns = [
    { field: 'securityCode', header: 'Security Code' },
    { field: 'exchangeCode', header: 'Exchange Code' },
    { field: 'securityName', header: 'Security Name' }
  ];

  constructor(private trustService: TrustComponentService, private messageService: MessageService) {}

  ngOnInit() {
    this.taxYears = getTaxYears(6, false, true);
    const [currentFY] = this.taxYears;
    this.financialYear = currentFY;
    this.getTrustComponents();
  }

  get exportFilename() {
    return `${this.isOutstanding ? 'Outstanding' : 'Completed'} Trust Components for FY ${this.financialYear.label}`;
  }

  get canExport() {
    return this.filteredData.length > 0 && !this.isLoading;
  }

  onClearSearch = () => {
    this.searchText = '';
    this.onSearchFilter();
  };

  onSearchFilter = () => {
    this.filteredData = this.data.filter((item) => (this.securityId ? item.securityId === this.securityId : true));
  };

  clear(table: Table) {
    table.clear();
  }

  getTrustComponents = () => {
    const { startDate, endDate } = this.financialYear;
    if (startDate && endDate) {
      this.isLoading = true;
      this.trustService.getTrustComponents(startDate, endDate, this.isOutstanding).subscribe({
        next: (res) => {
          this.data = (res as ITrustComponents[]) || [];
          this.onSearchFilter();
          this.isLoading = false;
        },
        error: (err) => {
          this.data = [];
          this.onSearchFilter();
          this.isLoading = false;
        }
      });
    }
  };
}
