import { Component, OnInit } from '@angular/core';
import { DISCLAIMER_MSG } from 'src/app/app.constants';

@Component({
  selector: 'app-common-disclaimer',
  templateUrl: './common-disclaimer.component.html',
  styleUrls: ['./common-disclaimer.component.css']
})
export class CommonDisclaimerComponent implements OnInit {
  disclaimerMsg = DISCLAIMER_MSG;

  constructor() {}

  ngOnInit(): void {}
}
