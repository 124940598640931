<ng-container *ngIf="data">
  <p-chip styleClass="pl-0 pr-3">
    <span class="bg-primary border-circle w-2rem h-2rem flex align-items-center justify-content-center">
      <i class="pi {{ getIconClassByData(data) }}"></i>
    </span>
    <span class="ml-2 font-medium">
      <a [href]="data.url" target="_blank">{{ data.label }}</a>
    </span>
  </p-chip>
</ng-container>
