import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import { Router } from '@angular/router';

@Component({
  selector: 'app-description-htmlrenderer',
  templateUrl: './description-htmlrenderer.component.html',
  styleUrls: ['./description-htmlrenderer.component.css']
})
export class DescriptionHTMLRendererComponent implements OnInit {
  // exploratory testing passed 2021-10-11

  @Input() html!: string;

  safeHTML!: SafeHtml;

  constructor(private sanitizer: DomSanitizer, private router: Router) {}

  ngOnInit(): void {
    // Use domPurify library here instead of this.sanitizer,
    // because for some reason the angular one does not allow data tags
    const domPurifySanitized = DOMPurify?.sanitize(this.html || '') || '';
    this.safeHTML = this.sanitizer.bypassSecurityTrustHtml(domPurifySanitized);
  }
}
