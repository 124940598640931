<p-accordion>

  <p-accordionTab header='Company Details'>
    <app-company-details-tab [companyDetailsData]='data.companyDetails'></app-company-details-tab>
  </p-accordionTab>

  <p-accordionTab header='Apportionment'>
    <app-apportionments-tab [data]='data.apportionmentEntries'></app-apportionments-tab>
  </p-accordionTab>

  <p-accordionTab header='Corporate Actions'>
    <app-corporate-actions-tab [data]='data.corporateActionData'></app-corporate-actions-tab>
  </p-accordionTab>

</p-accordion>
