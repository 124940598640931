<div class="container">
  <app-activity-type-header [type]="activityType"></app-activity-type-header>
  <ng-template appField></ng-template>

  <app-note-section [html]="activityDetails.notes" *ngIf="activityDetails.notes"></app-note-section>

  <app-document-list title="ATO Rulings" [data]="getATORulingDocumentLinks()"></app-document-list>
  <app-document-list title="Source Documents" [data]="getSourceDocuments()"></app-document-list>

  <app-note-section title="What to do in STM" *ngIf="activityDetails.whatToDoInStm" [html]="activityDetails.whatToDoInStm"></app-note-section>

  <p class="text-sm mt-4 disclaimer-section">
    <strong>
      <!-- <em> -->
      Disclaimer:
      <!-- </em> -->
    </strong>
    {{ disclaimerMsg }}
  </p>
</div>
