<app-small-header *ngIf="!isOrDividend"><span class="underlined-text"> Components</span></app-small-header>
<app-small-header *ngIf="isOrDividend"
  ><span class="underlined-text"> Components ({{ numberOfChoices }} choices)</span></app-small-header
>

<div class="table-box" *ngIf="!isOrDividend">
  <table>
    <tr>
      <th *ngFor="let field of fields" [hidden]="!shouldDisplayField(field)">
        {{ field.displayName }}
      </th>
    </tr>
    <tr *ngFor="let layer of dataLayers">
      <td *ngFor="let field of fields" [hidden]="!shouldDisplayField(field)">
        {{ displayCell(field, layer) }}
      </td>
    </tr>
  </table>
</div>
<div *ngIf="isOrDividend">
  <div *ngFor="let layer of dataLayers; let i = index">
    <div class="table-box">
      <table>
        <tr>
          <th *ngFor="let field of fields" [hidden]="!shouldDisplayField(field)">
            {{ field.displayName }}
          </th>
        </tr>
        <tr>
          <td *ngFor="let field of fields" [hidden]="!shouldDisplayField(field)">
            {{ displayCell(field, layer) }}
          </td>
        </tr>
      </table>
    </div>
    <span class="underlined-text" *ngIf="i < dataLayers.length - 1"> <h4>or</h4></span>
  </div>
</div>
