import { EventEmitter, Injectable } from '@angular/core';
import { CorporateAction } from 'src/api-connector/api-collections/cgt-view/cgt-view.types';

// This class simply hosts an event emitter that the dashboard will listen to,
// allowing all children components to request to launch the coop action details modal.

@Injectable()
export class CoopActionDetailsService {
  isLoading = false;
  selectedActivityId = '';

  constructor() {}

  // Value of the event emitter will be the ID of the activity to launch for
  public request = new EventEmitter<CoopActionDetailsRequest>();

  /**
   * Asks the host to open a modal containing details about an activity ID.
   */
  public requestCoopActionDetailsModal(request: CoopActionDetailsRequest): void {
    this.isLoading = true;
    this.selectedActivityId = request.activityId;
    this.request.emit(request);
  }

  public stopLoading = () => {
    this.isLoading = false;
    this.selectedActivityId = '';
  };
}

export interface CoopActionDetailsRequest extends CorporateAction {}
