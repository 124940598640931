import { Component, Input, OnInit } from '@angular/core';
import { toLabelValuePairArray } from '../buyback-details-renderer/buyback-details-renderer.component';
import { LabelValuePair } from '../../../../../../api-connector/api-collections/cgt-view/cgt-view.types';

@Component({
  selector: 'app-merge-details-renderer',
  templateUrl: './merge-details-renderer.component.html',
  styleUrls: ['./merge-details-renderer.component.css']
})
export class MergeDetailsRendererComponent implements OnInit {
  @Input() details: any;

  constructor() {}

  ngOnInit(): void {}

  computeInfoboxData(): LabelValuePair[] {
    const data = this.details;
    return toLabelValuePairArray({
      'Ex Date': data.exDate,
      Code: data.code,
      'Old Code': data.code, // TODO questionable - to be confirmed
      'New Code': data.newCode
    });
  }
}
