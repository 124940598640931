import { Component, OnInit, Input } from '@angular/core';
import { ApportionmentEntry } from '../../-models-/apportionment-data.model';

@Component({
  selector: 'app-apportionments-tab',
  templateUrl: './apportionments-tab.component.html',
  styleUrls: ['./apportionments-tab.component.css']
})
export class ApportionmentsTabComponent implements OnInit {

  @Input() data!: ApportionmentEntry[];

  constructor() { }

  ngOnInit(): void {
  }

}
