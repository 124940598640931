import { ActivatedRoute, Params, Router } from '@angular/router';
import { ViewFilter } from './view-filter.model';
import { CgtvDashboardComponent } from './cgtv-dashboard.component';
import { ApiHttpRequest } from '../../../api-connector/api-http-request';
import { ALL_VALUE } from 'src/app/app.constants';

export class CGTVDashboardParamsParser {
  constructor(private host: CgtvDashboardComponent, private route: ActivatedRoute, private router: Router) {}

  parseSearchParams(): ViewFilter | null {
    const params = this.route.snapshot.queryParams;

    // return null if invalid
    if (isValid(params)) {
      return {
        ...params,
        isValid: true
      } as ViewFilter;
    } else {
      if (params.hasOwnProperty('securityId')) this.initFromSecurityId(params.securityId);
      return null;
    }
  }

  /**
   * Fill up the rest of the properties from security id only
   * Will cause the router to navigate.
   * @private
   */
  private async initFromSecurityId(securityId: string) {
    const companyId = await this.getCompanyIdBySecurityId(securityId);
    const newParams = {
      securityId,
      companyId,
      financialYear: ALL_VALUE
      // reportType: 'All Activities'
    };

    await this.router.navigate([location.pathname], {
      queryParams: newParams
    });
  }

  private async getCompanyIdBySecurityId(securityId: string): Promise<string> {
    const res = await ApiHttpRequest.unpackSecurityId(securityId);
    return res.company.id;
  }
}

function isValid(params: Params) {
  return (
    params.hasOwnProperty('securityId') && params.hasOwnProperty('financialYear')
    // && params.hasOwnProperty('companyId')
    // && params.hasOwnProperty('reportType')
  );
}
