<p-dialog [modal]="true" [(visible)]="open" [style]="{ width: '80vw', minWidth: 'min(90vw, 500px)' }" [draggable]="false" [closable]="false">
  <!-- Don't try to put the style into CSS! It won't register with PrimeNG that way -->
  <ng-template pTemplate="header">
    <div style="width: 100%">
      <h3>
        Corporate Action Details
        <div style="float: right; transform: translate(2px, -9px)">
          <p-button
            icon="pi pi-envelope"
            [rounded]="true"
            [text]="true"
            pTooltip="For any enquiries on this corporate action please click here to send us an email."
            tooltipPosition="left"
            (click)="sendEnquiry()"
          />
          <p-button icon="pi pi-times" [rounded]="true" [text]="true" (click)="open = false" />
        </div>
      </h3>
    </div>
  </ng-template>
  <app-corporate-action-details
    [activityDetails]="details"
    [activityType]="activityType"
    [coopAction]="coopAction"
    *ngIf="details"
  ></app-corporate-action-details>
</p-dialog>
