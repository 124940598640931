import { isNumber } from 'lodash-es';
import { roundAndNormalise } from './round-and-normalise';

/**
 * The default processor
 * if the value is numeric, then multiply by the rate
 * @param cellData
 * @param layer
 */
export function defaultCellProcessor(cellData: any, layer: any) {
    if (isNumber(cellData) && isNumber(layer.rate) && layer.rate != 0) {
      const multiplied = cellData * layer.rate;
      return roundAndNormalise(multiplied);
    } else {
        return cellData;
    }
}
