// @ts-nocheck

import { ISO8601Date } from './iso8601-date.model';
import { DividendEntry } from './dividend-entry.model';

export class DividendEntryViewModel {

  // static fields
  // NOTE - these fields are actually used! they're just not picked up by angular
  itemId: string;
  security: string;
  units: number;
  exDate: ISO8601Date;
  payDate: ISO8601Date;
  activity: string;
  taxYear: number;
  rate: number;
  rawEntryRef: DividendEntry;

  constructor(arg0: {
    itemId: string,
    security: string,
    units: number,
    exDate: ISO8601Date,
    payDate: ISO8601Date,
    activity: string,
    taxYear: number,
    rate: number,
    rawEntryRef: DividendEntry
  }) {
    // assign all existing fields
    for (const key in arg0) {
      this[key] = arg0[key];
    }
  }

  // dynamically calculated "fields"
  get total(): number {
    return this.rate * this.units;
  }

  get ausGrossFranked(): number {
    return this.units * this.unitRates.ausGrossFranked;
  }

  get imputationCredit(): number {
    return this.units * this.unitRates.imputationCredit;
  }

  get ausNetFranked(): number {
    return this.units * this.unitRates.ausNetFranked;
  }

  private get unitRates() {
    return this.rawEntryRef.unitRates;
  }

}
