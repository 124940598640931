<p>
  Security Code: {{ data.securityCode }} <br/>
  Security Name: {{ data.securityName }}
</p>

<app-estimate-colour-box></app-estimate-colour-box>

<p *ngIf="data.apportionments.length === 0" class="mt-3">Not a stapled security.</p>

<p-table [resizableColumns]="true" [value]='data.apportionments' styleClass='mt-3' *ngIf="data.apportionments.length > 0">

  <ng-template pTemplate='header'>
    <tr>
      <th pResizableColumn *ngFor='let col of columnHeaders'>
        {{ col }}
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate='body' let-entry>
    <tr *ngFor="let descriptor of [renderTableRow(entry)]" [class.estimateRow]="descriptor.isEstimate">
      <td *ngFor='let cellValue of descriptor.columns'>
        {{ cellValue }}
      </td>
    </tr>
  </ng-template>

</p-table>
