import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewFilter } from '../view-filter.model';
import { ITaxYear, getTaxYears } from 'src/app/common/calender/tax-year.constants';
import { ALL_VALUE } from 'src/app/app.constants';

@Component({
  selector: 'app-dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrls: ['./dashboard-filters.component.css']
})
export class DashboardFiltersComponent implements OnInit {
  @Output() filterChange = new EventEmitter<ViewFilter>();

  // input values
  formGroup = new FormGroup({
    securityId: new FormControl(null, Validators.required),
    financialYear: new FormControl(ALL_VALUE, Validators.required)
    // reportType: new FormControl('All Activities')
  });

  taxYears: ITaxYear[] = [];

  constructor(private router: Router, public route: ActivatedRoute) {}

  ngOnInit(): void {
    this.taxYears = getTaxYears(6, true);
    const { securityId, financialYear } = this.route.snapshot.queryParams;
    this.formGroup.get('securityId')?.setValue(securityId);
    financialYear && this.formGroup.get('financialYear')?.setValue(financialYear);
  }

  get isFormDirty() {
    const values = this.formGroup.value;
    return !(values.securityId || values.financialYear !== ALL_VALUE);
  }

  onConfirm() {
    // we can assume the data is valid at this point
    const data = { ...this.formGroup.value };
    // data.securityId = data.securityId.id;
    // (data as any).companyId = this.estbSearchComponent.selectedCompany!.id;

    this.router.navigate(['./'], { relativeTo: this.route, queryParams: data });
  }

  onReset() {
    const data = {
      securityId: null,
      financialYear: ALL_VALUE
      // reportType: 'All Activities'
    };
    this.formGroup.setValue(data);

    this.router.navigate(['./'], { relativeTo: this.route, queryParams: data });
  }
}
