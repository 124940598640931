<div *ngIf='state === "Invalid"'>
  <app-notice-card content='Please select a security' icon='pi pi-search'></app-notice-card>
</div>

<div *ngIf="state === 'Error'">
  <app-notice-card content="Sorry an error occurred" icon="pi pi-exclamation-triangle"></app-notice-card>
</div>

<div *ngIf='state === "Loading"'>
  <app-notice-card content='Loading...' icon='pi pi-spin pi-spinner'></app-notice-card>
</div>

<div *ngIf='state === "Loaded"'>
  <app-dividend-calculator-table></app-dividend-calculator-table>
</div>

